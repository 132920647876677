.profile-card-container {
    display: flex;
    justify-content: center;
    align-items: center;  
  }
  
  .card {
    background-color: #102590;
    border-radius: 16px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
    color: #ffffff;
  }
  
  .banner {
    height: 150px;
    border-radius: 16px;
    background-size: cover;
    margin-bottom: 20px;
  }
  
  .avatar {
    width: 87px;
    height: 87px;
    border-radius: 50%;
    margin: -43px auto 0;
    border: 4px solid #ffffff;
  }
  
  .info {
    text-align: center;
  }
  
  .name {
    font-weight: bold;
    font-size: 1.5rem;
  }
  
  .job {
    font-size: 1rem;
    color: #666666;
  }
  
  .stats {
    display: flex;
    justify-content: space-between;
  }
  
  .stat {
    text-align: center;
  }
  
  .stat-number {
    font-weight: bold;
    font-size: 1.2rem;
  }
  
  .stat-label {
    font-size: 0.8rem;
    color: #666666;
  }
  